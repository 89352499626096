import React from 'react';
import bpLogo from './assets/images/logo-circle-full.png';
import msLogo from './assets/images/lolg-meteor.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-left">
          <img src={msLogo} className="meteor-logo" alt="meteor signs logo" />
          <div className="meteor-text">
            A division of METEOR SIGNS
          </div>
        </div>
        <div className="header-center">
          <img src={bpLogo} className="bp-logo" alt="boston pizza logo" />
          <div className="bp-text">
            Boston Pizza® Approved Vendor
          </div>
        </div>
        <div className="header-search"></div>
      </header>
      <nav className="navbar">
        <ul className="nav-list">
            <li><a href="https://bostonpizzaprint.com/product-category/all-products/safety-products/" className="nav-link active">Safety products</a></li>
            <li><a href="https://bostonpizzaprint.com/product-category/all-products/store-decals/" className="nav-link">Store decals</a></li>
            <li><a href="https://bostonpizzaprint.com/order-status/" className="nav-link">Order status</a></li>
            <li><a href="https://bostonpizzaprint.com/return-policy/" className="nav-link">Return policy</a></li>
            <li><a href="https://bostonpizzaprint.com/completed-job-form/" className="nav-link">Completed job ticket</a></li>
            <li><a href="https://bostonpizzaprint.com/contact/" className="nav-link">Contact</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default App;
